import React, { Component } from 'react';
import '../../App.css';
import './heading.css';
import outkast from '../../assets/outkast.gif';
import LinkWithArrow from '../linkarrow/linkarrow.js';
import ParticleBackground from '../particles/particles.js';

export default class Heading extends Component {
  static propTypes = {};

    render() {
        return (
            <div>
                <ParticleBackground />
                <div className="heading">
                    <div className="container heading-container">
                        <div className="heading-column">
                            <div className="heading-intro">
                                <h1>SpottieOttie<br />Dopaliscious</h1>
                                <p>Official animated music video for SpottieOttieDopaliscious. Created to celebrate the 25th anniversary of Outkast's third studio album, Aquemini.</p>
                                <LinkWithArrow text="Watch Now" href="./outkast"/>
                            </div>
                        </div>
                        <div className="heading-column">
                            <img src={outkast} alt="outkast-video"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
