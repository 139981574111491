import React from "react";
import ElevenEight1 from './assets/elevenEight1.gif';
import ElevenEight2 from './assets/elevenEight2.gif';
import ElevenEight3 from './assets/elevenEight3.gif';
import ElevenEight4 from './assets/elevenEight4.gif';
import ElevenEight5 from './assets/elevenEight5.gif';

const Eleven8 = () => {
  return (
    <div className="container page">
      <div className="intro"><b>11.8</b>  music video, designed and animated for NY beat maker ewonee.</div>
      <div className="section-video">
          <div className="iframe-wrapper"><iframe src="https://player.vimeo.com/video/167987780?h=094b9f1960&title=0&byline=0&portrait=0" className="iframe" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
      </div>

      <div className="section">
          <div>
              <h2>About</h2>
              <p>jERKS iNC. reached out to New York City based beat maker ewonee to craft an animated music video for one of the tracks off his 2015 LP “Radiance”. This was largely an experiment using a digital collage aesthetic mixed with some cell animation. The imagery was scanned from library books of dig sites and ancient sculptures found all over the world. The nature of sample based production is quite similar to that of a dig site, in that both parties are looking to unearth something hidden. This is what we came up with. Enjoy!</p>
          </div>
          <div>
              <h2>Credits</h2>
              <ul className="list-credits">
                  <li>
                      <span className="list-left">Director:</span>
                      <span className="list-right">James Dybvig</span>
                  </li>
                  <li>
                      <span className="list-left">Artist:</span>
                      <span className="list-right">ewonee</span>
                  </li>
              </ul>
          </div>
      </div>

      <div className="section-image-full">
        <img src={ElevenEight1} />
      </div>

      <div className="section-images">
          <img src={ElevenEight2} />
          <img src={ElevenEight3} />
          <img src={ElevenEight4} />
          <img src={ElevenEight5} />
      </div>
    </div>
  );
};

export default Eleven8;
