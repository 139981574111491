import React, { Component } from 'react';
import '../../App.css';
import './section-skwiz.css';
import SkwizLogo from './assets/skwiz-logo-with-shadow.svg';
import SkwizIpad from './assets/skwiz-callout-ipad.png';

export default class SectionSkwiz extends Component {
  static propTypes = {};

    render() {
        return (
            <div className='callout-skwiz'>
                <div className='callout-skwiz-gradient'>
                    <div className='callout-skwiz-grid'>
                        <div className='container'>
                            <div className='callout-skwiz-content'>
                                <div className='callout-skwiz-content-text'>
                                    <div className='callout-skwiz-content-text-container'>
                                        <img src={SkwizLogo} alt="Skwiz Logo" />
                                        <p>Take turns creating a single drawing – without knowing what the others have contributed – until the end!</p>
                                        {/* <LinkWithArrow text="View Press Kit" href="https://google.com"/> */}
                                        <a href="https://apps.apple.com/us/app/skwiz/id1611258643?itsct=apps_box_badge&amp;itscg=30200" style={{ display: "inline-block", overflow: "hidden", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px", width: "142px", height: "48px" }}><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1649462400&h=7bfb51012737f033cc0282ac00a71ebe" alt="Download on the App Store" style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px', borderBottomRightRadius: '8px', borderBottomLeftRadius: '8px', width: '142px', height: '48px'}} /></a>
                                    </div>
                                </div>
                                <img src={SkwizIpad} alt="iPad" className='callout-skwiz-content-image'/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}
