import React from "react";
// import OutkastGif from '../../../assets/cracked-porcelain2.gif';
// https://youtu.be/KwhBreZic8I?si=C5gdSkKnSNnAZfAW

const Outkast = () => {
  return (
    <div className="container page">
      <div className="intro"><b>SpottieOttieDopaliscious</b> music video produced for Outkast.</div>

      <div className="section-video">
        <div className="iframe-wrapper"><iframe  className="iframe" src="https://www.youtube-nocookie.com/embed/KwhBreZic8I?si=I2SsXV0-v3B3Ia37" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      </div>

      <div className="section">
          <div>
              <h2>About</h2>
              <p>OK Motion Club approached jERKS iNC. to create an animated music video for "SpottieOttieDopaliscious". Created to celebrate the 25th anniversary of Outkast's third studio album, Aquemini.</p>
          </div>
          <div>
            <h2>Credits</h2>
            <div className="grid-credits">
                <div>
                    <ul>
                        <li className="list-left">Animation Studio:</li>
                        <li className="list-right">OK Motion Club</li>
                    </ul>
                    <ul>
                        <li className="list-left">Director of Animation:</li>
                        <li className="list-right">James Dybvig</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      
      {/* <div className="section-image-full">
        <img src={OutkastGif} />
      </div> */}

    </div>
  );
};

export default Outkast;
