import React from "react";
import SkwizHeader from './assets/skwiz-header.gif';
import Skwiz1 from './assets/skwiz1.jpg';
import Skwiz2 from './assets/Someone_Draw_The_Bottom_Small.gif'

const Skwiz = () => {
  return (
    <div className="container page">
      <h1 className="intro"><b>Skwiz</b> is a collaborative drawing app. Based on the classic game exquisite corpse.</h1>
      <div className="section-video">
         <img src={SkwizHeader} />
      </div>

      <div className="section">
          <div>
              <h2>About</h2>
              <p>Skwiz is a play on the term exquisite corpse (“ik-Skwiz-it”). It’s the mobile drawing game where multiple people create one drawing without knowledge of what the others have contributed - until the end. It is the digital version of the surrealist drawing game “Exquisite Corpse,” in which players fold up a piece of paper, and pass it around so that each collaborator can only see the section they are drawing.</p>
              <p><a href="https://apps.apple.com/us/app/skwiz/id1611258643" target="_blank">View in the App Store</a></p>
          </div>
          <div>
              <h2>Credits</h2>
              <ul className="list-credits">
                  <li>
                      <span className="list-left">Developer:</span>
                      <span className="list-right">Michael McCombie</span>
                  </li>
                  <li>
                      <span className="list-left">Animator:</span>
                      <span className="list-right">James Dybvig</span>
                  </li>
                  <li>
                      <span className="list-left">Logo Designer:</span>
                      <span className="list-right">Vince Joy</span>
                  </li>
                  <li>
                      <span className="list-left">Producer:</span>
                      <span className="list-right">Aubrey McCombie</span>
                  </li>
              </ul>
          </div>
      </div>

      <div className="section-images">
          <img src={Skwiz1} />
          <img src={Skwiz2} />
      </div>
    </div>
  );
};

export default Skwiz;
