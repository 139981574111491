import React from "react";
import CrackedPorcelain from './assets/cracked-porcelain.gif';

const KrisKelly = () => {
  return (
    <div className="container page">
      <div className="intro"><b>Cracked Porcelain</b> music video produced for artist Kris Kelly.</div>

      <div className="section-video">
        <div className="iframe-wrapper"><iframe  className="iframe" src="https://www.youtube-nocookie.com/embed/jT3KBGB6tT4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      </div>

      <div className="section">
          <div>
              <h2>About</h2>
              <p>Kris Kelly approached jERKS iNC. to create an animated music video for his single “Cracked Porcelain” off of his debut LP. Kris relayed that the song was loosely a tale of two lovers who’d after a long time united, chosen separate lifestyles. From that conversation we developed and boarded out a story of two lost lovers that come across a temple of temptations. As one of our larger productions, we handled everything from beginning to end, implementing tracking charts to keep things in line. Enjoy!</p>
          </div>
          <div></div>
      </div>
      <div className="section-credits">
          <h2>Credits</h2>
          <div className="grid-credits">
              <div>
                  <ul>
                      <li className="list-left">Directors:</li>
                      <li className="list-right">Jack Kubizne</li>
                      <li className="list-right">Steve Winfield Meyer</li>
                  </ul>
                  <ul>
                      <li className="list-left">Producer:</li>
                      <li className="list-right">James Dybvig</li>
                  </ul>
                  <ul>
                      <li className="list-left">Director of Animation:</li>
                      <li className="list-right">James Dybvig</li>
                  </ul>
              </div>
              <div>
                  <ul>
                      <li className="list-left">Animators:</li>
                        <li className="list-right">Steve Winfield Meyer</li>
                        <li className="list-right">Donte Neal</li>
                        <li className="list-right">Austin Pettit</li>
                        <li className="list-right">Atesh Sakarya</li>
                        <li className="list-right">Hans Genares</li>
                  </ul>
                  <ul>
                      <li className="list-left">3D Animators & Compositors</li>
                        <li className="list-right">Jack Kubizne</li>
                        <li className="list-right">Whitney Alexander</li>
                  </ul>
              </div>
          </div>
      </div>
      <div className="section-image-full">
        <img src={CrackedPorcelain} />
      </div>

    </div>
  );
};

export default KrisKelly;
