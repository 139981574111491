import React from "react";
import SamEvian1 from './assets/sam-evian1.png';
import SamEvian2 from './assets/sam-evian2.png';
import SamEvian3 from './assets/sam-evian3.png';
import SamEvian4 from './assets/sam-evian4.gif';
import SamEvian5 from './assets/sam-evian5.gif';
import SamEvian6 from './assets/sam-evian6.gif';
import SamEvian7 from './assets/sam-evian7.gif';
import SamEvian8 from './assets/sam-evian8.gif';
import SamEvian9 from './assets/sam-evian9.png';
import SamEvian10 from './assets/sam-evian10.gif';

const SamEvian = () => {
    return (
        <div className="container page">
            <h1 className="intro"><b>Dream Free</b> music video for Sam Evian. Designed, animated, & directed by jERKS iNC.</h1>
            <div className="section-video">
                <div className="iframe-wrapper"><iframe src="https://player.vimeo.com/video/668369561?h=739ca383bc&title=0&byline=0&portrait=0" className="iframe" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            </div>

            <div className="section">
                <div>
                    <h2>About</h2>
                    <p><a href="">HAYMAKER</a> approached jERKS iNC. to design, animate and direct a music video for Sam Evian for Topo Chico's #TopoPremiere series. They provided the locked off footage of Sam and Hannah performing. Sam also shared 70's psychedelia art as visual inspiration to jump off from. We were given a ton of freedom to do what we do, and this is what we came up with. Enjoy!</p>
                </div>
                <div>
                    <h2>Credits</h2>
                    <ul className="list-credits">
                        <li>
                            <span className="list-left">Director:</span>
                            <span className="list-right">James Dybvig</span>
                        </li>
                        <li>
                            <span className="list-left">Client:</span>
                            <span className="list-right">Topo Chico</span>
                        </li>
                        <li>
                            <span className="list-left">Agency:</span>
                            <span className="list-right">HAYMAKER</span>
                        </li>
                        <li>
                            <span className="list-left">Artist:</span>
                            <span className="list-right">Sam Evian</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="section-images">
                <img src={SamEvian1} />
                <img src={SamEvian2} />
                <img src={SamEvian3} />
                <img src={SamEvian4} />
                <img src={SamEvian5} />
                <img src={SamEvian6} />
                <img src={SamEvian7} />
                <img src={SamEvian8} />
                <img src={SamEvian9} />
                <img src={SamEvian10} />
            </div>

        </div>
    );
};

export default SamEvian;
