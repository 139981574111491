import React, { useState } from 'react';
import './linkarrow.css';

const LinkWithArrow = ({ text, href }) => {
  const [arrowStyle, setArrowStyle] = useState({
    transition: 'transform 0.3s ease',
  });

  const handleHover = () => {
    setArrowStyle((prevStyle) => ({
      ...prevStyle,
      transform: 'translateX(4px)',
    }));
  };

  const handleLeave = () => {
    setArrowStyle((prevStyle) => ({
      ...prevStyle,
      transform: 'translateX(0)',
    }));
  };

  return (
    <a
      href={href}
      className="link-arrow"
      onMouseOver={handleHover}
      onMouseLeave={handleLeave}
    >
        <span style={{ marginRight: '8px' }}>{text}</span>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18" 
            height="8"
            fill="currentColor"
            className="bi bi-arrow-right-short"
            viewBox="0 0 18 8"
            style={arrowStyle}
        >
            <path d="M1 4H17" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M13 7L17 4L13 1V7Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </a>
  );
};

export default LinkWithArrow;

