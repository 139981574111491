import React, { useState } from 'react';
import '../../App.css';
import './header.css';
import logo from '../../assets/jerks-logotype.gif';
import Label from '../label/label.js';
import Project from '../project/project.js';

// Sample data
const tilesData = [
  {
    id: 1,
    title: 'Outkast',
    category: 'Music Videos',
    url: 'https://youtu.be/KwhBreZic8I?si=C5gdSkKnSNnAZfAW'
  },
  {
    id: 2,
    title: 'Skwiz',
    category: 'Games',
    url: './skwiz'
  },
  {
    id: 3,
    title: 'City Island',
    category: 'Series',
    url: './city-island'
  },
  {
    id: 4,
    title: 'Teenage Euthanasia',
    category: 'Series',
    url: './teenage-euthanasia'
  },
  {
    id: 5,
    title: 'Sam Evian',
    category: 'Music Videos',
    url: './dream-free'
  },
  {
    id: 6,
    title: 'Kris Kelly',
    category: 'Music Videos',
    url: './cracked-porcelain'
  },
  {
    id: 7,
    title: 'Ewonee',
    category: 'Music Videos',
    url: './11-8'
  },
];

const Tab = ({ label, onClick, isActive }) => (
  <div
    style={{
      color: isActive ? '#000000' : '#888888',
    }}
    className="tab-text"
    onClick={onClick}
  >
    {label}
  </div>
);

const Tile = ({ title, category, url }) => (
  <a href={url} className="menu-tile">
    <div className="menu-tile-info">
      <Project option={title}  fillColor="black" />
      <Label option={category} fillColor="black" />
    </div>
  </a>
);

const stopPropagation = (e) => {
    e.stopPropagation();
  };


const Header = () => {
  const [overlay, setOverlay] = useState('hide');
  const [activeTab, setActiveTab] = useState('All');

  const categories = ['All', 'Music Videos', 'Games', 'Series'];

  const toggleOverlay = () => {
    setOverlay((prevOverlay) => (prevOverlay === 'show' ? 'hide' : 'show'));
  };

  const filteredTiles =
    activeTab === 'All' ? tilesData : tilesData.filter((tile) => tile.category === activeTab);

  return (
    <header className="App-header">
      <div className="container">
        <div className="header-spacer"></div>
        <a className="logo-container" href="./">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <button onClick={toggleOverlay}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-width="3" d="M1.5 3h21M1.5 12h21M1.5 21h21"/></svg></button>

        <div id="overlay" className={`overlay ${overlay}`} onClick={toggleOverlay}>
          <div className="menu-overlay" onClick={stopPropagation}>
            <button onClick={toggleOverlay}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#F74188" stroke-linecap="round" stroke-width="3" d="m4.575 19.425 14.85-14.85M4.575 4.575l14.85 14.85"/></svg></button>
            <div className="menu-container">
              <div className="menu-description">
                <h3><b>jERKS iNC.</b> is an animation and mobile game studio</h3>
                <div className="menu-description-links">
                  <a href="./about">About</a>
                  <span>•</span>
                  <a href="./contact">Contact</a>
                </div>
              </div>
              <div className="menu-tabs">
                <div className="menu-tabs-container">
                  {categories.map((category) => (
                    <Tab
                      key={category}
                      label={category}
                      onClick={() => setActiveTab(category)}
                      isActive={activeTab === category}
                    />
                  ))}
                </div>
                <div className="menu-tiles">
                  {filteredTiles.map((tile) => (
                    <Tile key={tile.id} title={tile.title} category={tile.category} url={tile.url} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
