import React, { Component } from 'react';
import '../../App.css';
import './footer.css';

export default class Footer extends Component {
  static propTypes = {};

    render() {
        return (
            <div className='footer'>
                <div className='container footer-container'>
                    <span>©2019-2023 All Rights Reserved.</span>
                    <span>Skwiz® is a registered trademark of Jerks Inc.</span>
                    <div className='footer-socials'>
                        <a href='contact'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2c.312-1.732.466-3.49.46-5.25a29.005 29.005 0 0 0-.46-5.33Z"/><path stroke="#fff" strokeLinejoin="round" d="M1.5 5.5 12 13l10.5-7.5"/></svg></a>
                        <a href='https://www.instagram.com/jamesdybvig/?hl=en'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M20.623 5.818a3.777 3.777 0 0 0-.574-1.468c-.278-.409-.628-.706-1.013-.861C17.629 3 12 3 12 3s-5.629 0-7.036.536c-.385.155-.735.452-1.013.86a3.777 3.777 0 0 0-.574 1.468A47.726 47.726 0 0 0 3 12.024a47.73 47.73 0 0 0 .377 6.205c.107.535.31 1.022.587 1.414.278.392.623.674 1 .821C6.371 21 12 21 12 21s5.629 0 7.036-.536c.385-.155.735-.452 1.013-.86.28-.41.477-.916.574-1.468.256-2.017.382-4.063.377-6.113.009-2.08-.117-4.158-.377-6.205Z"/><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16 11.37a4 4 0 1 1-7.914 1.174A4 4 0 0 1 16 11.37Z"/><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.5 6.5h.01"/></svg></a>
                    </div>
                </div>
            </div>
        );
    }
}
