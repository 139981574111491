import React, { Component } from 'react';
import '../../App.css';
import './contact.css';
import LinkButton from '../linkbutton/linkbutton.js';

export default class Contact extends Component {
  static propTypes = {};

    render() {
        return (
            <div>
                <div className="contact">
                    <div className="container contact-container">
                        <div className="contact-column">
                            <div className="contact-intro">
                                <h1>Let’s work together.</h1>
                                <p>Have an animation project in the works? Let's make it happen!</p>
                                <LinkButton text="Contact Us" href="mailto: JDybvig@Gmail.com"/>
                            </div>
                        </div>
                        <div className="contact-column">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
