import React, { useState, useEffect } from 'react';
import './particles.css';
import JerksStar from './assets/jerks-star.gif';
import JerksGlobe from './assets/jerks-globe.gif';
import JerksBricks4 from './assets/jerks-bricks.gif';

const ParticleBackground = () => {
  const [particles, setParticles] = useState([]);

  useEffect(() => {
    const generateParticles = () => {
      const particleCount = Math.floor(window.innerWidth / 50); // Adjust this factor as needed
      const particlesArray = [];
      const shapes = ['globe', 'bricks', 'star', 'star', 'star', 'star', 'star', 'star'];
      const sizes = ['48px'];

      const checkOverlap = (newParticle, existingParticles) => {
        return existingParticles.some(
          (particle) =>
            Math.abs(newParticle.x - particle.x) < 50 && Math.abs(newParticle.y - particle.y) < 50
        );
      };

      for (let i = 0; i < particleCount; i++) {
        const shape = shapes[Math.floor(Math.random() * shapes.length)];
        const size = sizes[Math.floor(Math.random() * sizes.length)];
        const speed = Math.random() * 1;

        let newParticle;
        do {
          newParticle = {
            id: i,
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            fadeIn: Math.max(2, Math.random() * 2),
            shape,
            size,
            speed,
          };
        } while (checkOverlap(newParticle, particlesArray));

        particlesArray.push(newParticle);
      }

      setParticles(particlesArray);
    };

    const handleResize = () => {
      generateParticles();
    };

    generateParticles();

    window.addEventListener('resize', handleResize);

    const updateParticles = () => {
      setParticles((prevParticles) => {
        return prevParticles.map((particle) => ({
          ...particle,
          y: (particle.y + particle.speed) % window.innerHeight,
        }));
      });
    };

    const interval = setInterval(updateParticles, 50);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Run once on component mount

  return (
    <div className="particle-background">
      {particles.map((particle) => (
        <div
          key={particle.id}
          className={`particle ${particle.shape}`}
          style={{
            left: `${particle.x}px`,
            top: `${particle.y}px`,
            width: `${particle.size}`,
            height: `${particle.size}`,
            animation: `fadeInOut ${particle.fadeIn}s linear infinite`,
          }}
        >
          {particle.shape === 'star' && <img src={JerksStar} alt="shape particle" />}
          {particle.shape === 'globe' && <img src={JerksGlobe} alt="shape particle" />}
          {particle.shape === 'bricks' && <img src={JerksBricks4} alt="shape particle" />}
        </div>
      ))}
    </div>
  );
};

export default ParticleBackground;
