import React from "react";
import CityIsland1 from './assets/CI103_Animatic_SC03_070122_JD_V1.gif';
import CityIsland2 from './assets/CI105_Animatic_SC05_071122_AS_JD_V1.gif';
import CityIsland3 from './assets/CI108_Animatic_SC01_071422_AS_JD_V1_1.gif';

const CityIsland = () => {
    return (
        <div className="container page">
            <h1 className="intro"><b>City Island</b>, a short form animated series for PBS Kids.</h1>
            <div className="section-video">
                <div className="iframe-wrapper"><iframe className="iframe" src="https://www.youtube-nocookie.com/embed/J01xpOpJsHI?si=JttyWLRmmfj1mvEH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
            </div>
            <div className="section">
                <div>
                    <h2>About</h2>
                    <p><a href="https://www.augenblickstudios.com/" target="_blank" rel="noreferrer">Augenblick Studios</a> hired jERKS iNC.'s James Dybvig as a part of their Animatics team to bring their new animated series to life for PBS Kids. James was handed thumbnail drawings, with audio, that he would turn into on model, timed animatic files to be handed back for final animation.</p>
                </div>
                <div>
                    <h2>Credits</h2>
                    <ul className="list-credits">
                        <li>
                            <span className="list-left">Network</span>
                            <span className="list-right">PBS Kids</span>
                        </li>
                        <li>
                            <span className="list-left">Animation Studio:</span>
                            <span className="list-right">Augenblick Studios</span>
                        </li>
                        <li>
                            <span className="list-left">Art Director:</span>
                            <span className="list-right">Gemma Correll</span>
                        </li>
                        <li>
                            <span className="list-left">Animation Director:</span>
                            <span className="list-right">Katie Wendt</span>
                        </li>
                        <li>
                            <span className="list-left">Design Directors:</span>
                            <span className="list-right">Mimi Chiu, Jeremy Jusay</span>
                        </li>
                        <li>
                            <span className="list-left">Animatics:</span>
                            <span className="list-right">James Dybvig, Josh Pilch, Joy Kolitksy, Keith Stack</span>
                        </li>
                        <li>
                            <span className="list-left">Animation:</span>
                            <span className="list-right">Demente Studios</span>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div className="section-video">
                <div className="iframe-wrapper"><iframe className="iframe" src="https://www.youtube-nocookie.com/embed/nJw3oiBZXYM?si=9VL-wI_PsvbadVVA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
            </div>

            <div className="section-images">
                <img src={CityIsland1} />
                <img src={CityIsland2} />
                <img src={CityIsland3} />
            </div>

        </div>
    );
};

export default CityIsland;
