import React from "react";
import TeenageEuthanasia1 from './assets/TE102_ANIM_SC06B_SH03_052021_LW2_JJ_JA_AZ_JD.gif';
import TeenageEuthanasia2 from './assets/TE105_ANIM_SC17_SH16_062421_BC_JJ_BA2_JD.gif';
import TeenageEuthanasia3 from './assets/TE106_ANIM_SC10_SH06_081221_ZS_JJ_LG_JD.gif';
import TeenageEuthanasia4 from './assets/TE106_ANIM_SC20C_SH02_082421_DB2_NN_JD.gif';
import TeenageEuthanasia5 from './assets/TE107_ANIM_SC14_SH04_071421_AK_JJ2_JT4_JDV4.gif';
import TeenageEuthanasia6 from './assets/TE107_ANIM_SC16_SH03_080421_BC_JJ2_JA_EC_JT_JD.gif';

const TeenageEuthanasia = () => {
    return (
        <div className="container page">
            <h1 className="intro"><b>Teenage Euthanasia</b>, an animated series for Adult Swim.</h1>
            <div className="section-video">
                <div className="iframe-wrapper"><iframe className="iframe" src="https://www.youtube-nocookie.com/embed/fZZ3e_0CkCU?si=itZ6TOXMZUoXw0vB" title="Teenage Euthanasia - Youtube Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
            </div>
            <div className="section">
                <div>
                    <h2>About</h2>
                    <p><a href="https://www.augenblickstudios.com/" target="_blank" rel="noreferrer">Augenblick Studios</a> hired jERKS iNC.'s James Dybvig as a Lead Animator on the premier season of "Teenage Euthanasia". It aired on Cartoon Network's Adult Swim programming block in September of 2021. An assistance team cleaned up, colored, inbetweened and added lip sync to the shots he'd hand off.</p>
                </div>
                <div>
                    <h2>Credits</h2>
                    <ul className="list-credits">
                        <li>
                            <span className="list-left">Network:</span>
                            <span className="list-right">Cartoon Network</span>
                        </li>
                        <li>
                            <span className="list-left">Animation Studio:</span>
                            <span className="list-right">Augenblick Studios</span>
                        </li>
                        <li>
                            <span className="list-left">Production Companies:</span>
                            <span className="list-right">PFFR, Williams Street</span>
                        </li>
                        <li>
                            <span className="list-left">Animation Director:</span>
                            <span className="list-right">Katie Wendt</span>
                        </li>
                        <li>
                            <span className="list-left">Art Director:</span>
                            <span className="list-right">Abby Jame</span>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div className="section-video">
                <div className="iframe-wrapper"><iframe className="iframe" src="https://www.youtube-nocookie.com/embed/54BVBcWGcw4?si=X6e9RNoctIPHiafi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
            </div>

            <div className="section-images">
                <img src={TeenageEuthanasia1} alt="Teenage Euthanasia clip 1" />
                <img src={TeenageEuthanasia2} alt="Teenage Euthanasia clip 2" />
                <img src={TeenageEuthanasia3} alt="Teenage Euthanasia clip 3" />
                <img src={TeenageEuthanasia4} alt="Teenage Euthanasia clip 4" />
                <img src={TeenageEuthanasia5} alt="Teenage Euthanasia clip 5" />
                <img src={TeenageEuthanasia6} alt="Teenage Euthanasia clip 6" />
            </div>

        </div>
    );
};

export default TeenageEuthanasia;
