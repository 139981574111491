import React from 'react';
import './label.css';

const Label = ({ option, fillColor }) => {
  let icon, text;

  switch (option) {
    case 'Music Videos':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.54 6.42C22.4212 5.94541 22.1793 5.51057 21.8387 5.15941C21.498 4.80824 21.0708 4.55318 20.6 4.42C18.88 4 12 4 12 4C12 4 5.12001 4 3.40001 4.46C2.92925 4.59318 2.50198 4.84824 2.16135 5.19941C1.82072 5.55057 1.5788 5.98541 1.46001 6.46C1.14522 8.20556 0.991243 9.97631 1.00001 11.75C0.988786 13.537 1.14277 15.3213 1.46001 17.08C1.59097 17.5398 1.83831 17.9581 2.17815 18.2945C2.51799 18.6308 2.93883 18.8738 3.40001 19C5.12001 19.46 12 19.46 12 19.46C12 19.46 18.88 19.46 20.6 19C21.0708 18.8668 21.498 18.6118 21.8387 18.2606C22.1793 17.9094 22.4212 17.4746 22.54 17C22.8524 15.2676 23.0063 13.5103 23 11.75C23.0112 9.96295 22.8572 8.1787 22.54 6.42Z"
            stroke={fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle cx="11.25" cy="13.5" r="2" fill={fillColor} />
          <path
            d="M12.75 13.5V8L14.75 10"
            stroke={fillColor}
            stroke-miterlimit="16"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      text = 'Music Video';
      break;
    case 'Series':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.54 6.42C22.4212 5.94541 22.1793 5.51057 21.8387 5.15941C21.498 4.80824 21.0708 4.55318 20.6 4.42C18.88 4 12 4 12 4C12 4 5.12001 4 3.40001 4.46C2.92925 4.59318 2.50198 4.84824 2.16135 5.19941C1.82072 5.55057 1.5788 5.98541 1.46001 6.46C1.14522 8.20556 0.991243 9.97631 1.00001 11.75C0.988786 13.537 1.14277 15.3213 1.46001 17.08C1.59097 17.5398 1.83831 17.9581 2.17815 18.2945C2.51799 18.6308 2.93883 18.8738 3.40001 19C5.12001 19.46 12 19.46 12 19.46C12 19.46 18.88 19.46 20.6 19C21.0708 18.8668 21.498 18.6118 21.8387 18.2606C22.1793 17.9094 22.4212 17.4746 22.54 17C22.8524 15.2676 23.0063 13.5103 23 11.75C23.0112 9.96295 22.8572 8.1787 22.54 6.42Z"
            stroke={fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M10.4688 14.2025L14.7812 11.75L10.4688 9.29749V14.2025Z" fill={fillColor} stroke={fillColor} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      );
      text = 'Series';
      break;
    case 'Games':
      icon = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.5 8.5V11.5" stroke={fillColor} stroke-width="1.5" stroke-miterlimit="16" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7 10H10" stroke={fillColor} stroke-width="1.5" stroke-miterlimit="16" stroke-linecap="round" stroke-linejoin="round" />
          <circle cx="13.5" cy="10" r="0.75" fill={fillColor} />
          <circle cx="17.5" cy="10" r="0.75" fill={fillColor} />
          <circle cx="15.5" cy="8" r="0.75" fill={fillColor} />
          <circle cx="15.5" cy="12" r="0.75" fill={fillColor} />
          <path
            d="M14.614 15H12H9.386C8.55231 15 7.80607 15.5171 7.51334 16.2978L7.1164 17.3563C6.74563 18.345 5.80044 19 4.74448 19C3.09645 19 1.88719 17.4512 2.2869 15.8524L4.4319 7.27239C4.76578 5.93689 5.96573 5 7.34233 5H16.6577C18.0343 5 19.2342 5.93689 19.5681 7.27239L21.7131 15.8524C22.1128 17.4512 20.9036 19 19.2555 19C18.1996 19 17.2544 18.345 16.8836 17.3563L16.4867 16.2978C16.1939 15.5171 15.4477 15 14.614 15Z"
            stroke={fillColor}
            stroke-miterlimit="16"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
      text = 'Game';
      break;
    default:
      icon = null;
      text = 'Unknown Option';
  }

  return (
    <div className="label">
      <div>{icon}</div>
      <span style={{'color': fillColor}}>{text}</span>
    </div>
  );
};

export default Label;
